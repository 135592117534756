import React from 'react';
import { NFTIG } from './components/module';
//
import './App.css';

function App() {
  return (
    <div className="app">
      <NFTIG/>
    </div>
  ); 
}

export default App;
