import React from 'react';
// import ReactDOM from 'react-dom';
import './NFTIG.css';
import {/*Button,  IconButton,*/ Slider, Snackbar } from '@material-ui/core';
import /*Slide,*/ { SlideProps } from '@material-ui/core/Slide';

import {
    // updateConnectButton,
    connectWallet,
    mintTokens,
    // getCurrentWalletConnected,
    updateMintCount
} from "../../scripts/web3utils"
// import $ from 'jquery'



type TransitionProps = Omit<SlideProps, 'direction'>;
let appSuccess: boolean = false;
let appError: boolean = false;
let appSnackbarMessage: string = '';
function w3_open() {
    const sidebar = document.getElementById("mySidebar")
    if (sidebar) {
        sidebar.style.display = "block";
        sidebar.style.display = "block";
    }
}

function w3_close() {
    const sidebar = document.getElementById("mySidebar")
    if (sidebar) {
        sidebar.style.display = "none";
    }
}

function w3_open2() {
    const sidebar = document.getElementById("mySidebar2")
    if (sidebar) {
        sidebar.style.display = "block";
        sidebar.style.display = "block";
    }
}

function w3_close2() {
    const sidebar = document.getElementById("mySidebar2")
    if (sidebar) {
        sidebar.style.display = "none";
    }
}

// function toggleHiddenForm() {
//     // const form = document.getElementsByClassName("pickup-form")[0]
//     const form = Array.from(document.getElementsByClassName('pickup-form') as HTMLCollectionOf<HTMLElement>)[0]
//     const button = document.getElementById("mail-form")
//     if (form.style.display === "none") {
//         form.style.display = "block"
//         if (button) {
//             button.innerHTML = "CAN I GET GOODS SHIPPED?"
//         }
//     } else {
//         form.style.display = "none"
//         if (button) {
//             button.innerHTML = "CAN I GET GOODS SHIPPED?"
//         }
//     }
// }

// declare global {
//     interface Window {
//         fnames?: any;
//         ftypes?: any;
//     }
// }

let mintQty: number | number[] = 1;
let mintQty2: number | number[] = 1;

//   declare global {
//     interface Window {
//     }
//   }





function init() {
    console.log("init..")
    
    var slider1 = document.getElementById("mint-slider1")    
    var output = document.getElementById("slide-counter"); //x1    
    const mintCost = document.getElementById("mint-cost") // x1 @ .0888 Eth
    var sliderValue = "x"
    if (slider1) {
        slider1.oninput = function () {
            console.log("slider oninput")
            if (slider1) {
                sliderValue = slider1.innerText
                console.log(sliderValue)
            }
            if (output) {
                output.innerHTML = "x" + sliderValue; // Display the default slider value
            }
            if (mintCost) {
                mintCost.innerHTML = "x" + sliderValue + " @" + (.0888 * parseInt(sliderValue)) + " ETH"
            }
        }
    }

}





const transactions = {
    max: 10
};
const sliderMarks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: transactions.max,
        label: transactions.max
    },
];

function sliderValueText(value: number) {
    return `${value}`;
}


const onSliderUpdate2 = (event: any, value:number | number[]) => {
    mintQty2 = value;
    console.log(mintQty2)
    const output = document.getElementById("slide-counter2"); //x1    
    const mintCost2 = document.getElementById("mint-cost2") // x1 @ .0888 Eth
    if(output){
    output.innerHTML = "x" + mintQty2}
    if (mintCost2) {
      mintCost2.innerHTML = "x" + mintQty2 + " @" + (.0888 * Number(mintQty2)) + " ETH"
  }
    // console.log(mintQty)
  }



const onSliderUpdate = (event: any, value:number | number[]) => {
  mintQty = value;
  console.log(mintQty)
  const output = document.getElementById("slide-counter"); //x1    
  const mintCost = document.getElementById("mint-cost") // x1 @ .0888 Eth
  if(output){
  output.innerHTML = "x" + mintQty}
  if (mintCost) {
    mintCost.innerHTML = "x" + mintQty + " @" + (.0888 * Number(mintQty)) + " ETH"
}
  // console.log(mintQty)
}
// const onSliderUpdate = (event: any, value: number | number[]) => {
//     var mintQty = value;
//     console.log(mintQty)
//     var slider = document.getElementById("mint-slider");
//     const mintCost = document.getElementById("mint-cost")
//     var output = document.getElementById("mint-slider");
    
//     console.log("mintCost = ",mintCost)
//     if (slider) {
//         console.log(slider)
//         slider.oninput = function () {
//             if (output) {
//                 // output
//                 output.innerHTML = "x" + mintQty; // Display the default slider value
//             }
//             if (mintCost) {
//                 mintCost.innerHTML = "x" + mintQty + " @" + (.0888 * Number(value)) + " ETH"
//             }
//         }
//     }

// }

async function sendMintTokens(){
    await mintTokens(mintQty)
}
async function sendMintTokens2(){
    await mintTokens(mintQty2)
}







const displayNoneStyle = {
    display: "none"
}

const whiteTextStyle = {
    fontWeight: 700,
    color: "white"
}

const yellowTextStyle = {
    fontWeight: 700,
    color: "yellow"
}
const connectButtonStyle = {
    // "background-color": "rgb(71, 71, 71)",
    backgroundColor: "rgb(71, 71, 71)",
    color: "black",
    cursor: "not-allowed"
}
// const textAlignCenterStyle = {
//     // "text-align":"center"
//     justifyContent: "center"
// }


function NFTIG() {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const [transition/*, setTransition*/] = React.useState<
        React.ComponentType<TransitionProps> | undefined
    >(undefined);


    React.useEffect(() => {
        init();

        const connectButtons = Array.from(document.getElementsByClassName("connect-button"))
        connectButtons.forEach((button) => {
            button.addEventListener("click", connectWallet)
        })
        // const buyButtons = Array.from(document.getElementsByClassName("buy-button"))
        // buyButtons.forEach((button) => {
        //     button.addEventListener("click", sendMintTokens)
        // })
        const buyButton = document.getElementById("buy-button")
        buyButton?.addEventListener("click", sendMintTokens)
        
        const buyButton2 = document.getElementById("buy-button2")
        buyButton2?.addEventListener("click", sendMintTokens2)

        updateMintCount()

        setInterval(() => {
            updateMintCount()
        }, 15000)


    });

    return (
        <section className="section-wrapper minter">
            {appSuccess &&
                <Snackbar
                    className="success-snack"
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={transition}
                    message={appSnackbarMessage}
                    key={transition ? transition.name : ''}
                />
            }
            {appError &&
                <Snackbar
                    className="error-snack"
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={transition}
                    message={appSnackbarMessage}
                    key={transition ? transition.name : ''}
                />
            }



            <body>

                {/* 
	<!------------------- BOX OUTLINE -------------------> */}
                <div className="stroketop"></div>
                <div className="strokeleft"></div>
                <div className="strokeright"></div>
                <div className="strokebottom"></div>

                <div className="topnav">
                    <a href="#buy" className="btn1">
                        <div className="h7">1</div>
                    </a>
                    <a href="#login" className="btn2">
                        <div className="h7">2</div>
                    </a>
                    <a href="#metaverse" className="btn3">
                        <div className="h7">M</div>
                    </a>
                    <div onClick={() => w3_open2()} className="btn4">
                        <div className="h7">?</div>
                    </div>
                    
                    
                    
                    </div>


                {/* <!------------------- MARQUEE -------------------> */}
                <a href="https://nyc.nftinsidersguide.com/metamask" target="_blank" rel="noreferrer noopener">
                    <div className="noticemarquee w3-row">
                        <div className="track etrack">
                        ✷✷✷ DEAR MOBILE USERS ✷✷✷ YOU MUST USE THE METAMASK APP INTERNET BROWSER TO MINT/ACCESS GUIDE ✷✷✷ DEAR MOBILE USERS ✷✷✷ YOU MUST USE THE METAMASK APP INTERNET BROWSER TO MINT/ACCESS GUIDE ✷✷✷ DEAR MOBILE USERS ✷✷✷ YOU MUST USE THE METAMASK APP INTERNET BROWSER TO MINT/ACCESS GUIDE ✷✷✷ DEAR MOBILE USERS ✷✷✷ YOU MUST USE THE METAMASK APP INTERNET BROWSER TO MINT/ACCESS GUIDE 
                        </div>
                    </div>
               </a>



                {/* <!------landing-----------> */}
                <div className="box1"></div>
                <div className="box1b"></div>
                <div className="box1c"></div>
                {/* <!------------------- whitelist form -------------------> */}
                <nav className="w3-sidebar" style={displayNoneStyle} id="mySidebar">

                    <div onClick={() => w3_close()} className="w3-bar-item whitelist-close">
                        <div className="whitelist-close-btn"></div>
                    </div>


                    <iframe title="whitelist"
                        src="https://docs.google.com/forms/d/16ixRn7UETZOtT5758xic5huzNUYWHL1YHTQPqmdg7UU/viewform?embedded=true"
                        frameBorder="0">Loading…</iframe>
                    <div className="sidebargradient"></div>

                </nav>

     {/* <!------------------- how to info SHOULD BE NAV?-------------------> */}
     <div className="w3-sidebar" id="mySidebar2">


<div onClick={() => w3_close2()} className="w3-bar-item sidebar2-close">
    <div className="sidebar2-close-btn"></div>
</div>


         <h1>How to purchase an NFTroCard & access the Insiders Guide</h1>
         <h3>ON DESKTOP</h3>
         <p><span className="num">1.</span> Install <a href="https://metamask.io/" target="blank_">Metamask App</a></p>
        <p><span className="num">2.</span>  Come back to <a href="https://metamask.io/" target="blank_">www.NFTinsidersguide.app</a></p>
       <p><span className="num">3.</span>  <span className="step3">Connect Metamask</span></p>
        <p><span className="num">4.</span>  User slider to adjust how many NFTroCards you want</p>          
        <p><div className="step4"></div> <span className="num">5.</span>  <span className="step3">Buy NFTroCard(s)</span></p>
        <p>
        *this is called minting*
        </p>

            <p> <span className="num">6.</span>  Success! You now own an NFTroCard. Anytime you want to access the Insiders Guide, simply return to this website and 
            <br/>
            <span className="step6">Swipe NFTroCard</span>
            <br/>
            <br/>
            <span className="step7">Open Guide</span></p>
         <hr/>
         <h3>ON MOBILE</h3>
         <p>You <b><i>must</i></b> open this website through the <a href="https://metamask.io/" target="blank_">Metamask App</a> web browser. Regular browsers like Safari won’t let you connect your wallet on mobile. Then follow same instructions as desktop (above) once you’re in Metamask.</p>
         <hr/>
         <h2>New to the world of NFT's? Emersing in the NFT.NYC Conference is a great start. Use this guide to help you navigate spaces, learn vocabulary and have an extra good time of course.</h2>
         <hr/>
         <h3>CUSTOMER SERVICE</h3>
         <p>email <a href="mailto:eric@urconduit.com">eric@urconduit.com</a>
             <br/>
            or tweet <a href="https://www.twitter.com/urconduit" target="blank_">@urconduit</a>
            <br/>
            send ig dm <a href="https://instagram.com/eric.spiv.is.ur.plug" target="blank_">@eric.spiv.is.ur.plug</a></p>

</div>        


                {/* <!------------------- START MAIN PAGE CONTENT-------------------> */}






                <div className="w3-container box00">

                    <div className="w3-row box1d">

                    <div className="firstbuy">
                            <div className="slidecontainer">


                                <Slider
                                    id="mint-slider2"
                                    defaultValue={1}
                                    getAriaValueText={sliderValueText}
                                    aria-labelledby="discrete-slider-always"
                                    step={1}
                                    min={1}
                                    max={transactions.max}
                                    marks={sliderMarks}
                                    valueLabelDisplay="on"
                                    onChangeCommitted={onSliderUpdate2}
                                />



                                {/* <input type="range" min="1" max="10" value="1" className="slider" id="myRange" /> */}
                                <div id="slide-counter" className="slide-counter">
                               
                                </div>
                                <div className="quantitytotal h7">YOUR ORDER TOTAL</div>
                                <div className="h6" id="mint-cost2" style={{ backgroundColor: "#282525" }}>x1 @0.0888 ETH</div>
                                <div className="connect-button bigbutton">CONNECT METAMASK</div>
                                <div id="buy-button2" className="buy-button bigbutton">BUY NFTROCARD</div>

                              
                            </div>
                         </div> 
                        
                    </div>

                    {/* <!------about-----------> */}
                    <div className="w3-row box2" id="about">

            

<h1>Eric Spivak<br/>is your plug</h1>
                        <p> <span style={whiteTextStyle}>NFTroCard</span> is both utilitarian and an art collectable, giving the holder exclusive access to <a
                                href="http://urconduit.com/" target="_blank" rel="noreferrer" style={yellowTextStyle}>Eric “Motivate”
                                Spivak</a>.
                        <br /><br />
Who’s Eric? He is <a
                                href="https://www.instagram.com/urconduit" target="_blank" rel="noreferrer" style={yellowTextStyle}>@Urconduit</a> to all things NFTs, music, events & marketing. From Obama to DojaCat, his experience is a force and the list isn’t stopping any time soon. 
<br /><br />
Where is Eric now? In <span style={whiteTextStyle}>New York from November 1-4</span>, hosting for <span style={whiteTextStyle}>NFT.NYC Conference</span> and facilitating a chunk of other projects including this one.
<br /><br />
                The NFTroCard comes in 4 variations with increasing rarity, and value of additional goods per tier. All cards are random until minted. 
<br /><br />
Pick up physical goodies including a printed NFTroCard at the conference and meet Eric in person! <span style={whiteTextStyle}>Use the MetaMask app browser on mobile</span> to  <a
                                href="#login" rel="noreferrer" style={yellowTextStyle}>swipe your NFTroCard</a> and access <span
                            style={yellowTextStyle}>your private copy of the Insiders Guide NYC 2021 Edition</span>.
<br /><br />
But wait! The NFTroCard functions beyond NFT.NYC. Holding one means you are priority for Eric’s future events, suprise perks airdropped to you and more. 
                            
                            
                        </p>

                    </div>


                        {/* <!------details-----------> */}
<div className="w3-row box4" id="details">

                            <h1>Inside the Insiders Guide</h1>
<div className="guideimage"></div>


                     <p><br/>✷<br/>
                     We've made it easy for you this year. Navigate the conference and everything around it efficiently. This is a carefully curated directory bursting at the seams.
<br/>✷<br/>
        Over <span className="box4span">150+ Events</span> Private Parties, Satellite Pop-Ups, Fireside Chats, Activations, Dinner Parties, NY Brunches, Meet & Greets etc.
        <br/>✷<br/>
        List of <span className="box4span">500+ Speakers, Creators & Collectors</span> including @twitters for networking
<br/>✷<br/>
         <span className="box4span">Crypto / Blockchain Glossary</span> for quick reference, Eth conversion, more educational links 
         <br/>✷<br/>
        <span className="box4span">Travelers Guide to NY</span> Hotels, Museums, Parking, Eat all with walking distance estimates from the conference!
        <br/>✷<br/>
        <span className="box4span">Security & Safety</span> Tips & Tricks to guarantee a sound experience
        </p>

                            <h1>NFTroCard Details</h1>
                            <h2>Editions</h2>
                            <p>(4) variations of card
<br/>8005 cards total</p>

<p>All cards are random and will only reveal after minting. Every card is a winner, but the more you buy the more chances you have to win the rarest prize pack valued $30,000 USD.</p>
                            <h2>Floor Price</h2>
                            <p>0.0888 ETH</p>

                            <h2>Rarities & Goodies</h2>
                            <p><i>see below</i></p>



                            <div className="arrow"></div>
</div>


<div className="w3-row box4b">

                               <div className="w3-half tier0"> 
                                <div className="tier1">
                                    <div className="box4bcard"></div>
                                    <h2>EDITION ID: NYC01<br />
                                        TOTAL MINTED: 5000</h2>

                                    <div className="box4c">
                                        <h1>DIGITAL GOODS</h1>
                                        <p>NFTroCard (mp4)
                                            <span className="dot"><br />✷<br /></span>
                                            Access NFT Insiders Guide NYC 2021 Edition (pdf)
                                            <span className="dot"><br />✷<br /></span>
                                            Mixtape
                                            <br />
                                            <br /> 
                                            <div className="mixtape" ></div>
                                            <span className="dot"><br />✷<br /></span>
                                            Genesis whitelist access to separate collection by Motivate
                                        </p>
                                        <h1>PHYSICAL GOODS</h1>
                                        <p>NFTroCard</p>
                                    </div>

                            
                                </div>


                                <div className="tier2">
                                    <div className="box4bcard"></div>
                                    <h2>EDITION ID: NYC02<br />
                                        TOTAL MINTED: 2500</h2>


                                    <div className="box4c">
                                        <h1>DIGITAL GOODS</h1>
                                        <p>NFTroCard (mp4)
                                            <span className="dot"><br />✷<br /></span>
                                            Access NFT Insiders Guide NYC 2021 Edition (pdf)
                                            <span className="dot"><br />✷<br /></span>
                                            Mixtape
                                            <br />
                                            <br />
                                            <div className="mixtape" ></div>
                                            <span className="dot"><br />✷<br /></span>
                                            Genesis whitelist access to separate collection by Motivate
                                        </p>
                                        <h1>PHYSICAL GOODS</h1>
                                        <p>NFTroCard
                                        </p>
                                    </div>
                                </div>

                                </div>

                             <div className="w3-half tier0"> 

                                <div className="tier3">
                                    <div className="box4bcard"></div>
                                    <h2>EDITION ID: NYC03<br />
                                        TOTAL MINTED: 0500</h2>
                                    <div className="box4c">
                                        <h1>DIGITAL GOODS</h1>
                                        <p>NFTroCard (mp4)
                                            <span className="dot"><br />✷<br /></span>
                                            Access NFT Insiders Guide NYC 2021 Edition (pdf)
                                            <span className="dot"><br />✷<br /></span>
                                            Mixtape
                                            <br />
                                            <br />
                                            <div className="mixtape" ></div>
                                            <span className="dot"><br />✷<br /></span>
                                            Genesis whitelist access to separate collection by Motivate
                                        </p>
                                        <h1>PHYSICAL GOODS</h1>
                                        <p>NFTroCard
                                            <span className="dot"><br />✷<br /></span>
                                            Hoodie & Tote Bag
                                            <span className="dot"><br />✷<br /></span>
                                            Assorted Sticker Pack<span className="dot"><br />•<br /></span>Keychain & Bonus Item
                                        </p>

                                        <div className="hoodiefront"></div>
                                    </div>
                                </div>

                                <div className="tier4">
                                    <div className="box4bcard"></div>
                                    <h2>EDITION ID: NYC04<br />
                                        TOTAL MINTED: 0005</h2>
                                    <div className="box4c">
                                        <h1>DIGITAL GOODS</h1>
                                        <p>NFTroCard (mp4)
                                            <span className="dot"><br />✷<br /></span>
                                            Access NFT Insiders Guide NYC 2021 Edition (pdf)
                                            <span className="dot"><br />✷<br /></span>
                                            Mixtape
                                            <br />
                                            <br />
                                            <div className="mixtape" ></div>
                                            <span className="dot"><br />✷<br /></span>
                                            Genesis whitelist access to separate collection by Motivate
                                        </p>
                                        <h1>PHYSICAL GOODS</h1>
                                        <p>NFTroCard
                                            <span className="dot"><br />✷<br /></span>Hoodie & Tote Bag
                                            <span className="dot"><br />✷<br /></span>
                                            Assorted Sticker Pack
                                            <span className="dot"><br />✷<br /></span>Keychain & Bonus Item
                                            <span className="dot"><br />✷<br /></span>Vest
                                        </p>

                                        <div className="hoodiefront"></div>
                                        <div className="vest"> </div>

                                        <h1>OTHER GOODS</h1>
                                        <p>1 HR NFT Brand Audit, Marketing & Consulting Session w/Motivate & Urconduit Agency</p>
                                    </div>
                                </div>
                            </div>
</div>


                        {/* <!------pickup-----------> */}
                        <div className="w3-row box5" id="pickup">

                            <h1>COLLECT YOUR GOODS</h1>
                            <p className="box5p"><b>Digital</b> items like MP4 and mixtape will be released via NFT description (metadata). To access your copy of Insiders Guide, <a href="#login" className="swipelink">swipe NFTroCard below</a>.
                            <br/>
                            <br/><b>Physical</b> items can be picked up in person at NFT.NYC Conference. You will be airdropped instructions on where to meet Eric on Nov 3.</p>
                            <h1>NOT IN NEW YORK?</h1>
                            <p className="box5p">Fill out the form below. Additional shipping & handling charges may apply with an expected delivery of 3-4 weeks post event 11/5/21.</p>
                            
                          <div onClick={() => w3_open()}><h5 className="bigbutton">SHIPPING FORM</h5></div>
               
                        </div>






                    {/* <!------map menu-----------> */}
                    <div className="w3-row box3">



                        <div className="btn3 w3-row" id="buy">
                            <h1>1</h1>
                            <h2>Buy NFTroCard</h2>
                            <h3>1 of 4 cards will reveal after purchase</h3>
                            <h4>GOOD LUCK!</h4>
                            <h3>Floor Price</h3>
                            <h4>0.0888ETH</h4>
                            <h3>Purchase up to 10</h3>
                            <h4 className="beforeslider">USE SLIDER TO ADJUST QUANTITY</h4>
                            <div className="slidecontainer">


                                <Slider
                                    id="mint-slider1"
                                    defaultValue={1}
                                    getAriaValueText={sliderValueText}
                                    aria-labelledby="discrete-slider-always"
                                    step={1}
                                    min={1}
                                    max={transactions.max}
                                    marks={sliderMarks}
                                    valueLabelDisplay="on"
                                    onChangeCommitted={onSliderUpdate}
                                />



                                {/* <input type="range" min="1" max="10" value="1" className="slider" id="myRange" /> */}
                                <div id="slide-counter" className="slide-counter">
                               
                                </div>
                                <div className="quantitytotal h7">YOUR ORDER TOTAL</div>
                                <div className="h6" id="mint-cost">x1 @0.0888 ETH</div>
                                <div className="connect-button bigbutton">CONNECT METAMASK</div>
                                <div id="buy-button" className="buy-button bigbutton">BUY NFTROCARD</div>

                                <div className="h7">Must use metamask web browser on mobile!</div>
                            </div>
                         </div>  

                         <hr />

                            <div className="btn4 w3-row" id="login">
                                <h1>2</h1>
                                <h2>Login to Insiders Guide</h2>
                                <h3>Verify NFT & grab the guide!</h3>

                                <div className="connect-button bigbutton">SWIPE NFTROCARD</div>
                                <div id="open-guide" className="bigbutton" style={connectButtonStyle}>OPEN YOUR GUIDE</div>


                            </div>

                            <hr />
                            <div className="btn5 w3-row" id="metaverse">
                                <h1>M</h1>
                                <h2>Subway Metaverse</h2>
                                <h3>Explore VR Installation open to public (coming soon)</h3>
                                <h4>SUBMIT ARTWORK / ADS FOR DISPLAY</h4>
                                <a href="mailto:info@urconduit.com" target="_blank" rel="noreferrer"><div className="bigbutton">CONTACT US</div></a>
                            </div>

                        </div>
                  

                    

                        {/* <!------sponsors-----------> */}
                        <div className="w3-row box6">
                            <h1>Sponsors</h1>
                            <div className="sponsorscontainer">
                                <a href="https://nyc.nftinsidersguide.com/pdf-nftnyc" target="_blank" rel="noreferrer noopener"><div className="spon-1"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-deebies" target="_blank" rel="noreferrer noopener"><div className="spon-2"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-wooshi" target="_blank" rel="noreferrer noopener"><div className="spon-3"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-redape" target="_blank" rel="noreferrer noopener"><div className="spon-4"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-chijeu" target="_blank" rel="noreferrer noopener"><div className="spon-5"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-mci" target="_blank" rel="noreferrer noopener"><div className="spon-6"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-wisetoken" target="_blank" rel="noreferrer noopener"><div className="spon-7"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-0n1" target="_blank" rel="noreferrer noopener"><div className="spon-8"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-ethereal" target="_blank" rel="noreferrer noopener"><div className="spon-9"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-seek" target="_blank" rel="noreferrer noopener"><div className="spon-10"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-xrmiami" target="_blank" rel="noreferrer noopener"><div className="spon-11"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-mynt" target="_blank" rel="noreferrer noopener"><div className="spon-12"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-frog" target="_blank" rel="noreferrer noopener"><div className="spon-13"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-ozone" target="_blank" rel="noreferrer noopener"><div className="spon-14"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-niftykit" target="_blank" rel="noreferrer noopener"><div className="spon-15"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-harmony" target="_blank" rel="noreferrer noopener"><div className="spon-16"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-metavate" target="_blank" rel="noreferrer noopener"><div className="spon-17"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-smg" target="_blank" rel="noreferrer noopener"><div className="spon-18"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-urconduit" target="_blank" rel="noreferrer noopener"><div className="spon-19"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-blindboxes" target="_blank" rel="noreferrer noopener"><div className="spon-20"></div></a>
                                <a href="https://nyc.nftinsidersguide.com/pdf-ntts" target="_blank" rel="noreferrer noopener"><div className="spon-21"></div></a>
                            </div>

                        </div>

                        {/* <!------partners-----------> */}
                        <div className="w3-row box7">
                            <div className="logo2"></div>
                            <br/><br/>
                            <h2><u>IS MADE IN PARTNERSHIP WITH:</u><br />
                               
                                Chijeu Firm<br />
                                Metavate<br />
                                The Red Ape Family<br/>
                                Urconduit<br />
                                <br />
                                <u>THANK YOU</u><br />
                                Ennis, Assa, Jace, Dan, Matthew, StarkNaked, N2, Diggs. </h2>
                        </div>
                 
                        <footer>


                        <h1><span id="current-count">0000</span>/8005<br/>
                        <span className="available">CARDS SWIPED IN NYC 2021.
                        <br/>NEXT STOP ... M*****</span>
                        </h1> 
                            <div className="w3-row socials">

                                <a href="https://twitter.com/urconduit" target="_blank" rel="noreferrer">
                                    <div className="tw1"></div></a>
                                <a href="https://twitter.com/ericspivak" target="_blank" rel="noreferrer"><div className="tw2"></div> </a>
                                <a href="mailto:info@urconduit.com" target="_blank" rel="noreferrer"><div className="em"></div> </a>
                                <a href="https://www.instagram.com/eric.spiv.is.ur.plug" target="_blank" rel="noreferrer"><div className="ig"></div></a>

                            </div>
                            <br />



                            <p>© 2021 Urconduit LLC. All Rights Reserved.<br />
                                ✷ <a href="https://www.urconduit.com" target="_blank" rel="noreferrer" style={{ color: "#d79417" }}>www.urconduit.com</a> ✷</p>

                        </footer>

                 </div>

                {/* <!------------------- END MAIN PAGE CONTENT-------------------> */}



                {/* <!-- whitelist tab --> */}


               

                {/* <!--  Web3  --> */}
                {/* <script src="scripts/web3.min.js"> </script>
	<script src="scripts/web3utils.js"></script> */}

	

                {/* <!-- page additions --> */}

            </body>
        </section>
    );
}

export default NFTIG;